import React, { useState } from 'react';
import "../../components/user-profile/user-profile.css";
import GoogleBtn from '../../components/social-login/googleBtn';
import FacebookBtn from '../../components/social-login/facebookBtn';
import { useHistory } from 'react-router';
import CommonDashboardMarkup from '../common/CommonDashboardMarkup';

function SocialLogin(){
    const history = useHistory();

    const handleClick = () => {
        history.push('/companyprofile');
    }
    return (
        <>
            <CommonDashboardMarkup>
                <header>
                    <h2><b>Login With Us</b></h2>
                </header>
                <GoogleBtn />
                {/* <FacebookBtn /> */}
                <p>Are you a recruiter? <a href="" onClick={handleClick}>Register Here</a></p>
            </CommonDashboardMarkup>
        </>
    )
}

export default SocialLogin;