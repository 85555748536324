import React from 'react';
import Header from "../../pages/common/header";
import Footer from "../../pages/common/footer";
import loginCpntCSS from '../../components/loginCpntCSS.css' 
import {Link} from "react-router-dom";

class AvailableCourses extends React.Component {
    courseCode(code){
        console.log("Course code:"+code);
        localStorage.setItem('courseCode', code);
      }

    render(){
        return(
            <div>
                <div id="page-wrapper">
                        <Header />
                        <section id="features">
                           <div className ="container">
                               <div className ="row">
                                    <div className ="col-3 col-6-medium col-12-small">

                                     {/* <!-- Feature #1 --> */}
                                       <section>
                                       <Link to='/training' onClick={() => {this.courseCode('aws');}}><a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/aws.jpg')} alt="" /></a></Link>
                                           <h2 className="google-btn">Amazon Web Services</h2>
                                       </section>
                                   </div>
                                    <div className ="col-3 col-6-medium col-12-small">

                                        {/* <!-- Feature #2 --> */}
                                        <section>
                                        <a href="#" className ="bordered-feature-image" ><img src={require('../../assets/images/azure.jpg')} alt="" /></a>
                                       {/* <Link to='/training' onClick={() => {this.courseCode('azure');}}><a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/azure.jpg')} alt="" /></a></Link> */}
                                            <h2 className="google-btn">Microsoft Azure</h2>
                                        </section>
                                    </div>
                                    <div className ="col-3 col-6-medium col-12-small">

                                     {/* <!-- Feature #3 --> */}
                                        <section>
                                              <a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/pivotal.jpg')} alt="" /></a>
                                              {/* <Link to='/training' onClick={() => {this.courseCode('pivotal');}}><a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/pivotal.jpg')} alt="" /></a></Link> */}
                                              <h2 className="google-btn">Pivotal Cloud Foundry</h2>
                                        </section>

                                    </div>
                                    <div className ="col-3 col-6-medium col-12-small">

                                    {/* <!-- Feature #4 --> */}
                                         <section>
                                              {/* <Link to='/training' onClick={() => {this.courseCode('gcloud');}}><a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/gcp.jpg')} alt="" /></a></Link> */}
                                              <a href="#" className ="bordered-feature-image"><img src={require('../../assets/images/gcp.jpg')} alt="" /></a>
                                              <h2 className ="google-btn">Google Cloud</h2>
                                         </section>
                                </div>   
                          </div>
                     </div>
                  </section>
                      <Footer />
                </div>
            </div>
        )
    }

}
export default AvailableCourses