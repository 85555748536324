import React from "react";
import {Link} from 'react-router-dom'; 

const Sidebar = () => {
    return (
        <>
            <section>
                <header>
                    <h2>Quick Links</h2>
                </header>
                <ul class="link-list">
                    <li><Link  to="/contactus">Contact Us</Link></li>
                    <li><a href="#">Our Technical Articles</a></li>
                    <li><Link to ="/availablecourses">Technical Tranings</Link></li>
                    {/* <li><a href="#">Penatibus et magnis</a></li>
                    <li><a href="#">Dis parturient montes</a></li>
                    <li><a href="#">Nascetur ridiculus</a></li> */}
                </ul>
            </section>
            {/* <section>
                <header>
                    <h2>Looking for a Talent</h2>
                </header>
                <p>
                    Come and join our recuiter platfo. 
                </p>
                <ul class="link-list">
                    <li><a href="#">Sed dolore viverra</a></li>
                    <li><a href="#">Ligula non varius</a></li>
                    <li><a href="#">Dis parturient montes</a></li>
                    <li><a href="#">Nascetur ridiculus</a></li>
                </ul>
            </section> */}
            <section>
                <header>
                    <h2>Looking for a Job</h2>
                </header>
                <p>
                    Register your profile with us. Recruiters are looking for a profile similar to you. 
                </p>
                <ul class="link-list">
                    <li><Link to="/companyprofile">Register</Link></li>
                    {/* <li><a href="#">Ligula non varius</a></li>
                    <li><a href="#">Dis parturient montes</a></li>
                    <li><a href="#">Nascetur ridiculus</a></li> */}
                </ul>
            </section>
        </>
    )
}

export default Sidebar;