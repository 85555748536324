import apiClient from "../shared/axios.js";
import apimapping from "../shared/apimapping.js";

const Signin = async(userData) => {
    let res = await apiClient.post(apimapping.USER_REGISTRATION, userData
        // email: userData.email,
        // autheticatedMedium: userData.autheticatedMedium? userData.autheticatedMedium: 'normal',
        // password: userData.password? userData.password: '',
        // access_token : userData.accesstoken
    )
    console.log('login response: ', res);
    localStorage.setItem('profileStatus', res.data.profileStatus);
    return await res
}

export default Signin;

//http://escuelatech.com/api/user/authenticate