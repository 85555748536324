import React, {useState, useEffect} from 'react';
import TrainingRegService from '../../services/TrainingRegService';
import CommonDashboardMarkup from '../common/CommonDashboardMarkup';

const DisplayRegisteredTrainees = () => {
 const [trainees, setTrainees] = useState([]);
 
 useEffect(() => {
    TrainingRegService.getAllTrainees()
        .then(response => setTrainees(response.data))
}, []);

    return (
        <div>
            <CommonDashboardMarkup>
                <h2>All Registered Trainees</h2>
                <table id="trainees">
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Course Name</th>
                    </tr>
                    {
                        trainees.map(t => (
                            <tr>
                                <td>{t.firstName}</td>
                                <td>{t.lastName}</td>
                                <td>{t.email}</td>
                                <td>{t.phoneNumber}</td>
                                <td>{t.courseCode}</td>
                            </tr>
                        ))
                    }
                    
                </table>
            </CommonDashboardMarkup>
            
        </div>
    );
};

export default DisplayRegisteredTrainees;