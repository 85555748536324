import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import {withRouter} from "react-router-dom";
import recruiterService from '../../services/recruiterService';
import CommonDashboardMarkup from '../common/CommonDashboardMarkup';

class CompanyProfile extends Component{
    constructor(){
        super();
        this.state = {
            name: '', 
            email: '', 
            city: '',
            password: '',
            website: '',
            address: '',
            state: '',
            pincode: '',
            country: '',
            phoneNumber: '',
            message: '',
            errors: {
                name: '', 
                email: '', 
                city: '',
                password: '',
                website: '',
                address: '',
                state: '',
                pincode: '',
                country: '',
                phoneNumber: ''
            }
        }
    }

    validEmailRegex = 
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    validPasswordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d].{7,}$/);
    validPhoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; 
    validZipcodeRegex = RegExp(/^\d{5}$/);

    handleChange = (e) => {
        const {name, value} = e.target;
        let errors = this.state.errors;

        switch(name){
            case 'name':
                errors.name = value == '' ? 'Please enter name of your company' : '';
                break;
            case 'email':
                errors.email = this.validEmailRegex.test(value) ? '' : 'Please enter a valid email';
                break;
            case 'city':
                errors.city = value == '' ? 'Please enter city' : '';
                break;
            case 'password':
                errors.password = this.validPasswordRegex.test(value) ? '' : 'Password must have a minimum of eight characters, atleast one uppercase letter, one lowercase letter and one number';
                break;
            case 'website':
                errors.website = this.validEmailRegex.test(value) ? '' : 'Please enter a valid company website';
                break;
            case 'address':
                errors.address = value == '' ? 'Please enter company address' : '';
                break;
            case 'state': 
                errors.state = value == '' ? 'Please enter state' : '';
                break;
            case 'pincode': 
                errors.pincode = this.validZipcodeRegex.test(value) ? "": 'Please enter zipcode';
                break;
            case 'country': 
                errors.country = value == '' ? 'Please enter country' : '';
                break;
            case 'phoneNumber': 
                errors.phoneNumber = this.validPhoneRegex.test(value) ? '' : 'Please enter a valid phone number';
                break;
        }

        this.setState({
            errors,
            [name]: value
        })
        

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const recruiterDetails = {
            name: this.state.name, 
            email: this.state.email, 
            city: this.state.city,
            password: this.state.password,
            website: this.state.website,
            address: this.state.address,
            state: this.state.state,
            pincode: this.state.pincode,
            country: this.state.country,
            phoneNumber: this.state.phoneNumber
        }
        console.log('recruiter', recruiterDetails);
        recruiterService.submitRecruiterLoginData(recruiterDetails)
            .then(response => {
                console.log('Recruiter info from backend', response.data);
                // if(response.data.status === 'pending') {
                    this.setState({message: response.data});
                    console.log('message', this.state.message)
                    setTimeout(() => {
                        this.props.history.push("/landing");
                    }, 6000);
                // }
                
            })
    }

    cancel = () => {
        this.props.history.push('/landing');
    }

    form = () => {
        const {name, email, city, password, website, state, country, pincode, phoneNumber, address, errors} = this.state;
        const isEnabled = name.length > 0 && city.length > 0 && this.validEmailRegex.test(email) && this.validPasswordRegex.test(password) && 
                        website.length > 0 && state.length > 0 && country.length > 0 && this.validZipcodeRegex.test(pincode) > 0 && this.validPhoneRegex.test(phoneNumber) && address.length > 0;
        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="header-block">
                    <div>
                        <FontAwesomeIcon icon={faLaptopCode} size="3x"/>
                    </div>
                    <h3 style={{color: 'white'}}>Register Your Company With Us</h3>
                </div>
                <TextField 
                    id="outlined-basic" 
                    label="Company Name" 
                    variant="outlined"  
                    name="name" 
                    value={name}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.name.length > 0 && <span className="error">{errors.name}</span>}
                
                <TextField 
                    id="outlined-basic" 
                    label="Company Email" 
                    variant="outlined"  
                    name="email" 
                    value={email}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.email.length > 0 && <span className="error">{errors.email}</span>}

                <TextField 
                    id="outlined-basic" 
                    type="password"
                    label="Password" 
                    variant="outlined"  
                    name="password" 
                    value={password}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.password.length > 0 && <span className="error">{errors.password}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Company Website" 
                    variant="outlined"  
                    name="website" 
                    value={website}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.website.length > 0 && <span className="error">{errors.website}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Company Address" 
                    variant="outlined"  
                    name="address" 
                    value={address}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.address.length > 0 && <span className="error">{errors.address}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="City" 
                    variant="outlined"  
                    name="city" 
                    value={city}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.city.length > 0 && <span className="error">{errors.city}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="State" 
                    variant="outlined"  
                    name="state" 
                    value={state}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.state.length > 0 && <span className="error">{errors.state}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Country" 
                    variant="outlined"  
                    name="country" 
                    value={country}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.country.length > 0 && <span className="error">{errors.country}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Zipcode" 
                    variant="outlined"  
                    name="pincode" 
                    value={pincode}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.pincode.length > 0 && <span className="error">{errors.pincode}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Phone Number" 
                    variant="outlined"  
                    name="phoneNumber" 
                    value={phoneNumber}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.phoneNumber.length > 0 && <span className="error">{errors.phoneNumber}</span>}
                
                <br/>
            
                <button className="Back" onClick={this.cancel}>
                    Cancel
                </button>
                <button className="Next" disabled={!isEnabled}>
                    Register
                </button>
            </form>
        )
    }

    snackbar = () => {
        return (
            <div className="snackbar">
                <span style={{fontSize: '30px', paddingLeft: '10px'}}>{ this.state.message } </span> 
            </div>
        )
    }

    render(){
        return (
            <div>
                <CommonDashboardMarkup>
                {
                    this.state.message !== "" ? this.snackbar(): this.form()
                }
                </CommonDashboardMarkup>                       
            </div>
        )
    }
   
};

export default withRouter(CompanyProfile);