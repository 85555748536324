import React from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle,faUniversity } from '@fortawesome/free-solid-svg-icons'

const Jobs = (props) => {

    const {firstName, lastName, email, city, phone, highestDegree, fieldOfStudy, collegeName, collegeLocation} = props.user

    return (
        <>
        <Container>
            <Row>
                <Col sm={2}></Col>
                <Col sm={4}> <FontAwesomeIcon icon={faUserCircle} size="3x"/></Col>
                <Col sm={6}>
                <h3>Personal Information</h3>
                    <p>First Name: {firstName}</p>
                    <p>Last Name: {lastName}</p>
                    <p>Email: {email}</p>
                    <p>City: {city}</p>
                    <p>Phone Number: {phone}</p>
                </Col>
            </Row>
            <br />
            <div className="divider"></div>
            <br />
            <Row>
                <Col sm={2}></Col>
                <Col sm={4}> <FontAwesomeIcon icon={faUniversity} size="3x"/></Col>
                <Col sm={6}>
                    <h3>Education</h3>
                    <p>Highest Degree: {highestDegree}</p>
                    <p>Field of Study: {fieldOfStudy}</p>
                    <p>College Name: {collegeName}</p>
                    <p>College Location: {collegeLocation}</p>
                </Col>
            </Row>
            <br />
        </Container>
        <div className="divider"></div>
        <br />
           {/* <h3>Personal Information</h3>
           <p>First Name: {firstName}</p>
           <p>Last Name: {lastName}</p>
           <p>Email: {email}</p>
           <p>City: {city}</p>
           <p>Phone Number: {phone}</p> */}

           {/* <h3>Education</h3>
           <p>Highest Degree: {highestDegree}</p>
           <p>Field of Study: {fieldOfStudy}</p>
           <p>College Name: {collegeName}</p>
           <p>College Location: {collegeLocation}</p> */}
           
        </>
    )
}

export default Jobs