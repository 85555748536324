import apiClient from "../shared/axios.js";
import apimapping from "../shared/apimapping.js";

class TrainingRegService {
    addTraineeData = (params) => {
        return apiClient.post(apimapping.ADD_COURSE_TRAINEE, params);
    }
    getAllTrainees = () => {
        return apiClient.get(apimapping.GET_ALL_TRAINEES);
    }
}

export default new TrainingRegService;
