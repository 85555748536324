const CONTACT_US="/contact/submitform";
const USER_REGISTRATION = "/register/sociallogin";
const ADD_USER_PROFILE = "/register/adduserprofile";
const ADD_RESUME = "/upload/resume";
const FIND_USER = "/register/finduser?email=";
const GET_ALL_SKILLS = "/register/findallskills";
const RECRUITER_REGISTRATION = "/recruiter/addrecruiterprofile";
const ADD_COURSE_TRAINEE = "/training/addtrainee";
const GET_ALL_TRAINEES = "/training/fetchalltrainees";

export default {
    USER_REGISTRATION,
    ADD_USER_PROFILE,
    ADD_RESUME,
    FIND_USER,
    CONTACT_US,
    GET_ALL_SKILLS,
    RECRUITER_REGISTRATION,
    ADD_COURSE_TRAINEE,
    GET_ALL_TRAINEES
}