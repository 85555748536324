import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import {withRouter} from "react-router-dom";
import TrainingRegService from '../../services/TrainingRegService';
import CommonDashboardMarkup from '../common/CommonDashboardMarkup';


class TrainingRegistrationForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            firstName: '', 
            lastName: '', 
            email: '',
            phoneNumber: '',
            message: '',
            courseCode :'',
            errors: {
                firstName: '', 
                lastName: '', 
                email: '',
                phoneNumber: ''
            }
        }
    }

    validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    validPhoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; 
    
    componentDidMount = () => {
        this.setState({courseCode: localStorage.getItem('courseCode') });
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let errors = this.state.errors;

        switch(name){
            case 'firstName':
                // setErrors({companyName: value == '' ? 'Please enter name of your company' : ''})
                errors.firstName = value == '' ? 'Please enter first name' : '';
                break;
            case 'lastName':
                errors.lastName = value == '' ? 'Please enter last name' : '';
                break;
            case 'email':
                errors.email = this.validEmailRegex.test(value) ? '' : 'Please enter a valid email';
                break;
            case 'phoneNumber': 
                errors.phoneNumber = this.validPhoneRegex.test(value) ? '' : 'Please enter a valid phone number';
                break;
        }

        this.setState({
            errors,
            [name]: value
        })
        

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const trainingDetails = {
            firstName: this.state.firstName, 
            lastName: this.state.lastName, 
            email: this.state.email, 
            phoneNumber: this.state.phoneNumber,
            courseCode: this.state.courseCode
        }
        console.log('training details', trainingDetails);
        TrainingRegService.addTraineeData(trainingDetails)
            .then(response => {
                console.log('training info from backend', response.data);
                // if(response.data.status === 'pending') {
                    this.setState({message: response.data});
                    // this.props.history.push("/trainingmsg");
                    console.log('message', this.state.message)
                    setTimeout(() => {
                        this.props.history.push("/landing");
                    }, 6000);
                // }
                
            })
    }

    cancel = () => {
        this.props.history.push('/availablecourses');
    }

    form = () => {
        const {firstName, lastName, email, phoneNumber, errors, courseCode} = this.state;
        const isEnabled = firstName.length > 0 && lastName.length > 0 && this.validEmailRegex.test(email) && this.validPhoneRegex.test(phoneNumber);
        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        return (
            <form onSubmit={this.handleSubmit}>                             
                <div className="header-block">
                    <div>
                    {
                        courseCode !== "" 
                        ?
                            <img src={require(`../../assets/css/images/${courseCode}.jpg`)} alt="" className="header-img"/>
                        :   <FontAwesomeIcon icon={faGraduationCap} size="3x"/>
                    }
                    </div>
                    <h3 style={{color: 'white'}}>Train with Us</h3>
                </div>
                    {
                        courseCode != "" ? <h3>You have selected our {courseCode.toUpperCase()} training.</h3> : "" 
                    }   
                <TextField 
                    id="outlined-basic" 
                    label="First Name" 
                    variant="outlined"  
                    name="firstName" 
                    value={firstName}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.firstName.length > 0 && <span className="error">{errors.firstName}</span>}
                
                <TextField 
                    id="outlined-basic" 
                    label="Last Name" 
                    variant="outlined"  
                    name="lastName" 
                    value={lastName}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.lastName.length > 0 && <span className="error">{errors.lastName}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Email" 
                    variant="outlined"  
                    name="email" 
                    value={email}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.email.length > 0 && <span className="error">{errors.email}</span>}

                <TextField 
                    id="outlined-basic" 
                    label="Phone Number" 
                    variant="outlined"  
                    name="phoneNumber" 
                    value={phoneNumber}
                    onChange={this.handleChange} 
                    style={styles}
                />
                {errors.phoneNumber.length > 0 && <span className="error">{errors.phoneNumber}</span>}
                
                <br/>
            
                <button className="Back" onClick={this.cancel}>
                    Cancel
                </button>
                <button className="Next"  disabled={!isEnabled}>
                    Submit
                </button>
            </form>
        )
    }

    snackbar = () => {
        return (
            <div className="snackbar">
                <span style={{fontSize: '30px', paddingLeft: '10px'}}>{ this.state.message } </span> 
            </div>
        )
    }
    

    render(){
        return (
            <div>
                <CommonDashboardMarkup>
                {
                    this.state.message !== "" ? this.snackbar() : this.form()
                }
                </CommonDashboardMarkup>                          
            </div>
        )
    }
   
};

export default withRouter(TrainingRegistrationForm);