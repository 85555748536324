import React, { Component } from "react";
import { render } from 'react-dom';
import Footer from '../../pages/common/footer';
import Header from '../../pages/common/header';
import Sidebar from '../../pages/common/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { TextareaAutosize, TextField } from '@material-ui/core';
import contactService from '../../services/contactService';
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            message: '',
            statusMessage: '',
            hidden: true,
            errors: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                message: ''
            }
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    validEmailRegex =
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    validPhoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


    handleChange = input => e => {
        const { name, value } = e.target;
        let errors = this.state.errors;
        console.log(name);
        switch (name) {
            case 'firstName':
                console.log("firstName erroe message - " + value);
                errors.firstName = value == "" ? 'Please enter your First Name' : '';
                break;
            case 'lastName':
                errors.lastName = value == "" ? 'Please enter your Last Name' : '';
                break;
            case 'phoneNumber':
                errors.phoneNumber = this.validPhoneRegex.test(value) ? '' : 'Please enter a valid Phone Number';
                break;
            case 'email':
                errors.email = this.validEmailRegex.test(value) ? '' : 'Please enter a valid email';
                break;
            case 'message':
                errors.message = value == "" ? 'Please enter your Message' : '';
                break;
        }
        this.setState({
            errors,
            [input]: e.target.value
        })
    }

    cancel = e => {
        e.preventDefault();
        this.props.history.push('/dashboard');
    }

    handleClick = (e) => {
        e.preventDefault();
        const contactUs = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            message: this.state.message
        }
        contactService.submitContactUs(contactUs)
            .then(response => {
                console.log("Submit Contact US ", response.data);
                this.props.history.push('/completedcontactus');
            });

    };

    render() {
        const { firstName, lastName, phoneNumber, email, message, handleChange, errors, statusMessage, hidden } = this.props;

        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        return (
            <>
                <CommonDashboardMarkup>
                    <div className="header-block">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} size="3x" />
                        </div>
                        <h3 style={{ color: 'white' }}>Write to us</h3>
                    </div>
                    <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={firstName}
                        onChange={this.handleChange('firstName')}
                        style={styles}
                    />
                    {this.state.errors.firstName.length > 0 && <span className="error">{this.state.errors.firstName}</span>}
                    <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        name="lastName"
                        value={lastName}
                        onChange={this.handleChange('lastName')}
                        style={styles}
                    />
                    {this.state.errors.lastName.length > 0 && <span className="error">{this.state.errors.lastName}</span>}
                    <TextField
                        id="outlined-basic"
                        label="Email Address"
                        variant="outlined"
                        name="email"
                        value={email}
                        onChange={this.handleChange('email')}
                        style={styles}
                    />
                    {this.state.errors.email.length > 0 && <span className="error">{this.state.errors.email}</span>}
                    <TextField
                        id="outlined-basic"
                        label="PhoneNumber"
                        variant="outlined"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={this.handleChange('phoneNumber')}
                        style={styles}
                    />
                    {this.state.errors.phoneNumber.length > 0 && <span className="error">{this.state.errors.phoneNumber}</span>}
                    <TextareaAutosize
                        id="outlined-basic"
                        name="message"
                        label="message"
                        value={message}
                        onChange={this.handleChange('message')}
                        rowsMin= '4'
                        width='10'
                        style={styles}
                    />
                    {this.state.errors.message.length > 0 && <span className="error">{this.state.errors.message}</span>}
                    <br />
                    <button onClick={this.handleClick} className="Next">Submit</button>
                    <button onClick={this.cancel} className="Back" > Cancel  </button>
                    <br />
                    {<span>{this.state.statusMessage}</span>}
                </CommonDashboardMarkup>                 
            </>
        )
    }
}

export default ContactUs;