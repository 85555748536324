import React, {Component} from "react"
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'

class JobDetails extends Component{
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    render(){
        const {handleChange, jobLocation, jobTitle, jobCompany, description, errors} = this.props;
        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        const isEnabled = jobLocation.length > 0 && jobTitle.length > 0 && jobCompany.length > 0 && description.length > 0;
        return (
            
            <>
                <div className="header-block">
                    <div>
                        <FontAwesomeIcon icon={faLaptopCode} size="3x"/>
                    </div>
                    <h3 style={{color: 'white'}}>Work Experience</h3>
                </div>
                 <TextField 
                    id="outlined-basic" 
                    label="Job Title" 
                    variant="outlined"  
                    name="jobTitle" 
                    value={jobTitle}
                    onChange={handleChange('jobTitle')} 
                    style={styles}
                />
                 {errors.jobTitle.length > 0 && <span className="error">{errors.jobTitle}</span>}
                 <TextField 
                    id="outlined-basic" 
                    label="Company" 
                    variant="outlined"  
                    name="jobCompany" 
                    value={jobCompany}
                    onChange={handleChange('jobCompany')} 
                    style={styles}
                />
                 {errors.jobCompany.length > 0 && <span className="error">{errors.jobCompany}</span>}
                <TextField 
                    id="outlined-basic" 
                    label="City" 
                    variant="outlined"  
                    name="jobLocation" 
                    value={jobLocation}
                    onChange={handleChange('jobLocation')} 
                    style={styles}
                />
                 {errors.jobLocation.length > 0 && <span className="error">{errors.jobLocation}</span>}
                <TextField
                    id="outlined-multiline-flexible"
                    label="Description"
                    multiline
                    rowsMax={5}
                    value={description}
                    onChange={handleChange('description')}
                    variant="outlined"
                    style={styles}
                />
                 {errors.description.length > 0 && <span className="error">{errors.description}</span>}
                <br/>
               
                <button className="Back" onClick={this.back}>
                    Back
                </button>
                <button className="Next" onClick={this.continue} disabled={!isEnabled}>
                    Next
                </button>
            </>
        )
    }
}

export default JobDetails