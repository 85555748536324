import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const User = (props) => {

    const {jobTitle, company, jobLocation, description} = props.job
    console.log('job', props.job)

    return (
        <>
            {
                props.job.map((j) => (
                    <>
                    <Container>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={4}> <FontAwesomeIcon icon={faLaptopCode} size="2x"/></Col>
                        <Col sm={6}>
                            <h3>Job History</h3>
                            <p>Job Title: {j.jobTitle}</p>
                            <p>Company Name: {j.company}</p>
                            <p>City: {j.jobLocation}</p>
                            <p>Job Description: {j.description}</p> 
                        </Col>
                    </Row>
                    <br />
                    </Container>
                    <div className="divider"></div>
                    <br />
                </>
                ))
            }
        </>
    )
 }

export default User