import React, {Component} from "react"
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import userService from "../../services/userService"

class Skills extends Component{
    // state={
    //     skills: []
    // }
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

 
    render(){
        const {handleChangeSkills, options, isMulti, skill, required} = this.props
        const isEnabled = skill ? skill.length > 0: "";
       
        return (
            <>
                <div className="header-block">
                    <div>
                        <FontAwesomeIcon icon={faCode} size="3x"/>
                    </div>
                    <h3 style={{color: 'white'}}>Skills</h3>
                </div>
                <label>Please include 3-5 of your top skills to help you stand out.</label>
                <label>
                <Select 
                    options={options} 
                    onChange={handleChangeSkills}
                    isMulti={isMulti}
                    value={skill}
                    required={required}
                >
                    
                </Select>
                </label>
                    
                <button className="Back" onClick={this.back}>Back</button>
                <button className="Next" onClick={this.continue} disabled={!isEnabled}>Next</button>
            </>
        )
    }
}

export default Skills