import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const User = (props) => {

    const {skillName} = props.skill;
    console.log('skill', props.skill);

    return (
        <>
            <Container>
                <Row>
                    <Col sm={2}></Col>  
                    <Col sm={4}> <FontAwesomeIcon icon={faCode} size="2x"/></Col>
                    <Col sm={6}>
                        <h3>Skills</h3>
                        { props.skill.map((s, index) => (
                        <p>{index+1}: {s.skillName}</p>
                        ))
                        }
                    </Col>
                </Row>
                <br />
            </Container>
            <div className="divider"></div>
            <br />
        </>
    )
 }

export default User