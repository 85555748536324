import apiClient from "../shared/axios.js";
import apimapping from "../shared/apimapping.js";
  
class userService {
    submitUserData = (params) => {
        return apiClient.post(apimapping.USER_REGISTRATION, params);
    }
    addUserProfile = (params) => {
        return apiClient.post(apimapping.ADD_USER_PROFILE, params);
    }
    addResume = (formData, email) => {
        const options = {
            headers: {'userEmail': email}
          };
        return apiClient.post(apimapping.ADD_RESUME, formData, options);
    }
    getUserDetails = (email) => {
        return apiClient.get(apimapping.FIND_USER+email);
    }

    getAllSkills = () => {
        return apiClient.get(apimapping.GET_ALL_SKILLS);
    }
}


export default new userService;