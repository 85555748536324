import React, {useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

const CompleteProfilePage = (props) => { 
    useEffect(() => {
        const timer = setTimeout(() => {
            props.history.push('/dashboard');
            localStorage.setItem('profileStatus', 'complete');
        }, 30000);
        return () => clearTimeout(timer);
    }, []);

    const handleClick = (event) => {
        event.preventDefault();
        props.history.push('/dashboard');
        localStorage.setItem('profileStatus', 'complete');
    }
    
        return (
            <>
                <CommonDashboardMarkup>
                    <div className="success-header">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x"/>
                        <span style={{fontSize: '30px', paddingLeft: '10px'}}>Thank you for completing your Profile. You will be redirected to the Dashboard. </span>
                    </div>
                    <button onClick={handleClick} className="Next" style={{width: '100%'}}>Go To Dashboard</button>
                </CommonDashboardMarkup>                                
            </>
        )
}

export default CompleteProfilePage
