import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import './assets/css/font-awesome.min.css';
import './assets/css/main.css';

import  LandingPage  from "./pages/LandingPage";
import SocialLogin from './components/social-login/SocialLogin';
import Wizard from './components/user-profile/Wizard';
import AddResume from './components/user-profile/AddResume';
import CompleteProfilePage from './components/user-profile/CompleteProfilePage';
import MyProfile from './components/display-user-profile/MyProfile';
import ContactUs from './components/contact-us/ContactUs';
import CompleteContactUs from './components/contact-us/CompleteContactUs';
import Dashboard from './components/dashboard/Dashboard';
import AvailableCourses from "./components/training-registration/AvailableCourses.jsx"
import CompanyProfile from './components/company-profile/CompanyProfile';
import TrainingRegistrationForm from './components/training-registration/TrainingRegistrationForm';
import TrainingRegistrationSuccessMessage from './components/training-registration/TrainingRegistrationSuccessMessage';
import DisplayRegisteredTrainees from './components/training-registration/DisplayRegisteredTrainees';

const PrivateRoute = ({component: Wizard}) => (
    <Route render={(props) => (
        localStorage.getItem('isLogined') === 'true' 
            ? <Wizard {...props} />
            : <Redirect to="/landing" />
    )} />
)


function App() {
    return (
        <div>
            <Router basename={'/homepage'}>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to='landing' />}></Route>
                    <Route exact path="/landing" component={LandingPage}></Route>
                    <Route path="/login" component={SocialLogin}></Route>
                    <PrivateRoute path="/userprofile" component={Wizard}></PrivateRoute>
                    {/* <Route path="/addresume/:email" component={AddResume}></Route> */}
                    <Route path="/addresume" component={AddResume}></Route>
                    <Route path="/completedprofile" component={CompleteProfilePage}></Route>
                    <Route path="/myprofile" component={MyProfile}></Route>
                    <Route path="/dashboard" component={Dashboard}></Route>
                    <Route path="/contactus" component={ContactUs}></Route>
                    <Route path="/completedcontactus" component={CompleteContactUs}></Route>
                    <Route path="/availablecourses" component={AvailableCourses}></Route>
                    <Route path="/companyprofile" component={CompanyProfile}></Route>
                    <Route path="/training" component={TrainingRegistrationForm}></Route>                    
                    <Route path="/displaytrainees" component={DisplayRegisteredTrainees}></Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;