import React from 'react';
import Header from './common/header';
import Feature from './common/feature';
import Content from './common/content';
import Footer from './common/footer';
import Banner from './common/banner';

const LandingPage = () => {
    return (
        <>
           <div id="page-wrapper">
               <Header />
               <Banner />
               <Feature />
               <Content />
               <Footer />
            </div>
        </>
    )
}

export default LandingPage;