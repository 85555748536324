import React from "react";
import Footer from "../../pages/common/footer";
import Header from "../../pages/common/header";
import Sidebar from "../../pages/common/sidebar";

function CommonDashboardMarkup(props){
    return (
        <>
             <div id="page-wrapper">
                        <Header />
                            <section id="content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-3 col-12-medium">
                                            <Sidebar />
                                        </div>
                                        <div className="col-9 col-12-medium imp-medium">
                                            <section>
                                                {props.children}
                                            </section> 
                                        </div>
                                    </div>
                                </div>
                            </section>
                        <Footer />
                    </div>
        </>
    )
}

export default CommonDashboardMarkup;

