import React from "react";
import ProfileSummaryPage from "./ProfileSummaryPage";
import Wizard from "../user-profile/Wizard";

const MyProfile = () => {
    return (
        <>
            {
                localStorage.getItem('profileStatus') === 'complete' 
                    ? <ProfileSummaryPage />
                    : <Wizard />
            }
        </>
    )
}

export default MyProfile