import React from 'react';
import loginCpntCSS from '../../components/loginCpntCSS.css'

class DashboardFeatures extends React.Component {
    render(){
        return(
            <div>
                <div className ="container">
                     <div className ="row">
                          <div className ="col-4 col-6-medium col-12-small">
                               <div id="features">
                                   <section >
                                       <header>
                                           <h2 className ="google-btn">Job Search</h2>
                                       </header>
                                    </section>
                                </div>
                            </div>

                            <div className ="col-4 col-6-medium col-12-small">
                                 <div id="features">
                                      <section >
                                           <h2 className ="google-btn">Trainings</h2>
                                      </section>
                                   </div>
                            </div>

                            <div className ="col-4 col-6-medium col-12-small">
                                 <div id="features">
                                      <section>
                                           <h2 className ="google-btn">Design&Development </h2>
                                      </section>
                                 </div>
                            </div>
                        </div>
                    </div>
             </div>
        )
    }
}
export default  DashboardFeatures