import React from "react";
import Footer from '../../pages/common/footer';
import Header from '../../pages/common/header';
import Sidebar from "../../pages/common/sidebar";
import userService from '../../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

class AddResume extends React.Component {
    state = {
        resume: null
    }
 

    handleChange = (event) => {
        this.setState({
            resume: event.target.files[0]
        })
    };

    handleClick = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("file", this.state.resume);
        console.log("resume", this.state.resume); 
        console.log("formData", formData); 
        // userService.addResume(formData, this.props.location.state.email)
        userService.addResume(formData, this.props.match.params.email)
            .then(response => {
                console.log("Resume from backend: ", response.data);
                this.props.history.push('/completedprofile');
            });
    };

    render(){
        
        return (
            <>
                 <CommonDashboardMarkup>
                    <div className="success-header">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x"/>
                        <span style={{fontSize: '30px', paddingLeft: '10px'}}>Your Profile has been successfully completed. </span>
                    </div>
                    <div className="header-block">
                        <div>
                            <FontAwesomeIcon icon={faFileUpload} size="3x"/>
                        </div>
                        <h3 style={{color: 'white'}}>Please upload your resume</h3>
                    </div>
                    <input 
                        type="file"
                        onChange={this.handleChange} 
                    />
                    <button onClick={this.handleClick} className="Next" style={{width: '100%'}}>Upload</button>
                 </CommonDashboardMarkup>                        
            </>
        )
    }
    
}
  
export default AddResume;