import React, {Component} from "react"
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'

class AllInfo extends Component{
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    render(){ 
        
        const {firstName, lastName, city, phone, email, jobTitle, jobCompany, jobLocation, description, highestDegree, collegeLocation, collegeName, fieldOfStudy, resume, url, skill, handleSubmit} = this.props;
        const formData = new FormData();
        formData.append("file", resume);
        console.log('resume', resume);
        
        return (
            <>
                <form onSubmit={handleSubmit}>
                    <div className="header-block">
                        <div>
                            <FontAwesomeIcon icon={faClipboardList} size="3x"/>
                        </div>
                        <h3 style={{color: 'white'}}>Review the information you entered.</h3>
                    </div>
                            {/* TODO profile image
                            <Typography variant="h5" component="h2">
                                <img src={URL.createObjectURL(profileImage)} width="250"/><br />
                            </Typography> */}
                    <div style={{paddingLeft: '20px'}}>
                        <Typography  color="textSecondary">
                            <div style={{padding: '20px', marginBottom: '10px', border: '1px solid teal', borderRadius: '25px'}}>
                                <h3 style={{color: 'black', fontWeight: 'bold'}}>Personal Information</h3>
                                First Name: <b>{firstName}</b><br />
                                Last Name: <b>{lastName}</b><br />
                                City: <b>{city}</b><br />
                                Phone Number: <b>{phone}</b><br />
                                Email: <b>{email}</b><br />
                            </div>
                            <br />
                            <div style={{padding: '20px', marginBottom: '10px', border: '1px solid teal', borderRadius: '25px'}}>
                                <h3 style={{color: 'black', fontWeight: 'bold'}}>Education</h3>
                                Level of Education: <b>{highestDegree.value}</b>
                                <br />
                                Field of Study: <b>{fieldOfStudy}</b><br />
                                College Name: <b>{collegeName}</b><br />
                                College Location: <b>{collegeLocation}</b><br />
                            </div>
                            <br />
                            <div style={{padding: '20px', marginBottom: '10px', border: '1px solid teal', borderRadius: '25px'}}>
                                <h3 style={{color: 'black', fontWeight: 'bold'}}>Work Experience</h3>
                                Job Title: <b>{jobTitle}</b><br />
                                Company: <b>{jobCompany}</b><br />
                                City: <b>{jobLocation}</b><br />
                                Description: <b>{description}</b><br />
                            </div>
                            <br />
                            <div style={{padding: '20px', marginBottom: '10px', border: '1px solid teal', borderRadius: '25px'}}>
                                <h3 style={{color: 'black', fontWeight: 'bold'}}>Skills</h3>
                                Skills: {skill.map((item) => {
                                    return <b key={item.id}>{item.value} </b>
                                })} <br />
                                {/* Skills: {skill.map((item) => {
                                    return <b key={item.skillId}>{item.skillName} </b>
                                })} <br /> */}
                            </div>
                        </Typography>
                    </div>
                    <CardActions>
                        <button className="Back" onClick={this.back}>Back</button>
                        <button className="Next">Save And Continue</button>
                    </CardActions>
                </form>
            </>
        )
    }
}

export default AllInfo