import React from "react";
import DashboardFeatures from "../../components/dashboard/DashboardFeatures";
import DashBoardContent from "../../components/dashboard/DashBoardContent.jsx"
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

function Dashboard(){

    return (
        <>
            <CommonDashboardMarkup>
                <h2>Dashboard</h2>
                <DashboardFeatures/> <br/>
                <DashBoardContent/> 
            </CommonDashboardMarkup>                                                         
        </>
    )
}

export default Dashboard;

