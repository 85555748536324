import React from "react";
import {Link} from 'react-router-dom'; 

const Banner = () => {
    return (
        <section id="header">
        <div id="banner">
            <div class="container">
                <div class="row">
                    <div class="col-6 col-12-medium">
                        {/* <!-- Banner Copy --> */}
                            <p> AWS Training / Certification registrations are open </p>
                            <Link to="/availablecourses"><a href="#" class="button-large">Please register here ! </a> </Link> 
                    </div>
                    <div class="col-6 col-12-medium imp-medium">
                    <p> Our experienced consulting teams will deliver the ultimate outcomes like your dream team would, without the hiring hassles </p>
                        {/* <!-- Banner Image --> */}
                            <a href="#" class="bordered-feature-image"><img src={require('../../assets/images/banner.jpg')} alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Banner;