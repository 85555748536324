import React, {useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

const CompleteContactUs = (props) => { 
    useEffect(() => {
        const timer = setTimeout(() => {
            props.history.push('/dashboard');
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    
        return (
            <>
                <CommonDashboardMarkup>
                    <div className="success-header">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x"/>
                        <span style={{fontSize: '30px', paddingLeft: '10px'}}>Thank you for writing to us, we will get back to you soon. You will be redirected to the Dashboard. </span>
                    </div>
                </CommonDashboardMarkup>                             
            </>
        )
}

export default CompleteContactUs
