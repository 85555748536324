import React from "react";
import userService from "../../services/userService";
import User from "./User";
import Jobs from "./Jobs";
import SkillDetails from "./SkillDetails";
import {withRouter} from "react-router-dom";
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";


class ProfileSummaryPage extends React.Component {
    state = {
        userDetails : {
            user: '',
            prevJobs: [],
            skills: []
        }
    }

    back = e => {
        e.preventDefault();
        this.props.history.push("/dashboard"); 
    }

    componentWillMount(){
        userService.getUserDetails(localStorage.getItem('email'))
            .then(response => this.setState({userDetails: {user: response.data.user, prevJobs: response.data.prevJobs, skills: response.data.skills}}))
            console.log('userdetails', this.state.userDetails);  
    }

    render(){
        console.log('user', this.state.userDetails.user); 
        console.log('job', this.state.userDetails.prevJobs); 
        console.log('skills', this.state.userDetails.skills); 

        return (
            <>
                <CommonDashboardMarkup>
                    <h2>Profile Summary</h2>
                    <User user={this.state.userDetails.user}/>
                    <Jobs job={ this.state.userDetails.prevJobs} />
                    <SkillDetails skill={this.state.userDetails.skills} />
                    <button className="Back" onClick={this.back}>Back To Dashboard</button>
                    <button className="Next">Edit Profile</button>
                </CommonDashboardMarkup>                              
            </>
        )
    }
    
}

export default withRouter(ProfileSummaryPage)