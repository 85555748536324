import React, {Component} from "react"
import "./user-profile.css";
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "react-router-dom"

class PersonalInfo extends Component{
    
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    cancel = e => {
        e.preventDefault(); 
        this.props.history.push('/dashboard');
    }

    render(){
        const {handleChange, firstName, lastName, city, phone, email, handleChangeImage, errors, validPhoneRegex, validEmailRegex} = this.props;
        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        // const isEnabled = firstName.length > 0 && lastName.length > 0 && city.length > 0 && validPhoneRegex.test(phone) && validEmailRegex.test(email);
        const isEnabled = city.length > 0 && validPhoneRegex.test(phone);
        // const element = <FontAwesomeIcon icon={faUserCircle} size="3x"/>
        
        return (
            <>
                <div className="header-block">
                    <div>
                        <FontAwesomeIcon icon={faUserCircle} size="3x"/>
                    </div>
                    <h3 style={{color: 'white'}}>Personal Information</h3>
                </div>
                <TextField 
                    id="outlined-basic" 
                    label="First Name" 
                    variant="outlined"  
                    name="firstName" 
                    value={firstName}
                    onChange={handleChange('firstName')} 
                    style={styles}
                />
                {errors.firstName.length > 0 && <span className="error">{errors.firstName}</span>}
                <TextField 
                    id="outlined-basic" 
                    label="Last Name" 
                    variant="outlined"  
                    name="lastName" 
                    value={lastName}
                    onChange={handleChange('lastName')} 
                    style={styles}
                />
                {errors.lastName.length > 0 && <span className="error">{errors.lastName}</span>}
                 <TextField 
                    id="outlined-basic" 
                    label="City" 
                    variant="outlined"  
                    name="city" 
                    value={city}
                    onChange={handleChange('city')} 
                    style={styles}
                />
                {errors.city.length > 0 && <span className="error">{errors.city}</span>}
                <h3>Contact Information</h3>
                <TextField 
                    id="outlined-basic" 
                    label="Phone Number" 
                    variant="outlined"  
                    name="phone" 
                    value={phone}
                    onChange={handleChange('phone')} 
                    style={styles}
                />
                 {errors.phone.length > 0 && <span className="error">{errors.phone}</span>}
                <TextField 
                    id="outlined-basic" 
                    label="Email Address" 
                    variant="outlined"  
                    name="email" 
                    value={email}
                    onChange={handleChange('email')} 
                    style={styles}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                 {errors.email.length > 0 && <span className="error">{errors.email}</span>}
                 <br />
                 <button className="Back" onClick={this.cancel}>
                    Cancel
                </button>
                <button className="Next" onClick={this.continue} disabled={!isEnabled}>
                    Next
                </button>
                
            </>
        )
    }
}

export default withRouter(PersonalInfo)