import React from "react";
import {Link} from 'react-router-dom'; 

const Feature = () => {
    return (
        <section id="features">
        <div class="container">
            <div class="row">
                <div class="col-3 col-6-medium col-12-small">

                    {/* <!-- Feature #1 --> */}
                        <section>
                        <Link to="/availablecourses">
                            <a href="#" class="bordered-feature-image"><img src={require('../../assets/images/pic01.jpg')} alt="" /></a>
                        </Link>
                            <h2>Technology Trainings</h2>
                            <p>
                                In <strong>Escuela</strong>, we hava a bunch of Technical
                                training <a href="#"> programs </a> for beginers and professionsls 
                                <Link to="/availablecourses"> Click here for Trainings</Link>
                            </p>
                        </section>

                </div>
                <div class="col-3 col-6-medium col-12-small">

                    {/* <!-- Feature #2 --> */}
                        <section>
                            <a href="http://escuelatech.com/homepage" class="bordered-feature-image"><img src={require('../../assets/images/pic02.jpg')} alt="" /></a>
                            <h2>Technical Articles</h2>
                            <p>
                                We are pasionate about writing articles on emerging technologies.
                            </p>
                        </section>

                </div>
                <div class="col-3 col-6-medium col-12-small">

                    {/* <!-- Feature #3 --> */}
                        <section>
                            <a href="#" class="bordered-feature-image"><img src={require('../../assets/images/pic03.jpg')} alt="" /></a>
                            <h2>Software Development</h2>
                            <p>
                                We are in to software application developemnt <a href="#">as</a> ,
                                Just reach out to us if you are looking for a technical talents 
                            </p>
                        </section>

                </div>
                <div class="col-3 col-6-medium col-12-small">

                    {/* <!-- Feature #4 --> */}
                        <section>
                            <a href="#" class="bordered-feature-image"><img src={require('../../assets/images/pic04.jpg')} alt="" /></a>
                            <h2>Open source projects</h2>
                            <p>
                            Are you looking for a way to practice your coding skills? Look no further than contributing to open source software.Open source software relies on contributors who are willing to 
                            improve existing projects, bring them up-to-date, and maintain them
                            </p>
                        </section>

                </div>
            </div>
        </div>
    </section>
    )
}

export default Feature;