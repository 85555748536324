import React from "react";

const Content = () => {
    return (
        <section id="content">
        <div class="container">
            <div class="row aln-center">
                <div class="col-4 col-12-medium">

                    {/* <!-- Box #1 --> */}
                        <section>
                            <header>
                                <h2>Who We Are</h2>
                                <h3>A subheading about who we are</h3>
                            </header>
                            <a href="#" class="feature-image"><img src="images/pic05.jpg" alt="" /></a>
                            <p>helps developers explore the Java ecosystem and simply be better engineers.We publish to-the-point guides and courses, 
                            with a strong focus on building web applications, Spring, Spring Security, and RESTful APIs.
                            </p>
                        </section>

                </div>
                <div class="col-4 col-6-medium col-12-small">

                    {/* <!-- Box #2 --> */}
                        <section>
                            <header>
                                <h2>What We Do</h2>
                                <h3>We can offer you training consulting services that include at a minimum</h3>
                            </header>
                            <ul class="check-list">
                                <li>Recruiting & Staffing</li>
                                <li>Technology Training</li>
                                <li>Design & Develop Software Applications </li>
                                <li>Technology Blogging</li>
                            </ul>
                        </section>

                </div>
                <div class="col-4 col-6-medium col-12-small">

                    {/* <!-- Box #3 --> */}
                        <section>
                            {/* <header>
                                <h2>What People Are Saying</h2>
                                <h3>And a final subheading about our clients</h3>
                            </header>
                            <ul class="quote-list">
                                <li>
                                    <img src="images/pic06.jpg" alt="" />
                                    <p>"Neque nisidapibus mattis"</p>
                                    <span>Jane Doe, CEO of UntitledCorp</span>
                                </li>
                                <li>
                                    <img src="images/pic07.jpg" alt="" />
                                    <p>"Lorem ipsum consequat!"</p>
                                    <span>John Doe, President of FakeBiz</span>
                                </li>
                                <li>
                                    <img src="images/pic08.jpg" alt="" />
                                    <p>"Magna veroeros amet tempus"</p>
                                    <span>Mary Smith, CFO of UntitledBiz</span>
                                </li>
                            </ul> */}
                        </section>

                </div>
            </div>
        </div>
    </section>
    )
}

export default Content;