import apiClient from "../shared/axios.js";
import apimapping from "../shared/apimapping.js";

class recruiterService {
    submitRecruiterLoginData = (params) => {
        return apiClient.post(apimapping.RECRUITER_REGISTRATION, params);
    }
}

export default new recruiterService;
