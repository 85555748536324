import React, {Component} from "react";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity } from '@fortawesome/free-solid-svg-icons'

class EducationDetails extends Component{
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep(); 
    }

    render(){
        const {errors, handleChange,handleChangeDegree, highestDegree, collegeLocation, collegeName, fieldOfStudy, options, isMulti, required} = this.props
        const styles = {
            width: '100%',
            marginBottom: '10px'
        }
        const isEnabled = collegeLocation.length > 0 && collegeName.length > 0 && fieldOfStudy.length > 0;
        
        return (
            <>
                <div className="header-block">
                    <div>
                    <FontAwesomeIcon icon={faUniversity} size="3x"/>
                    </div>
                    <h3 style={{color: 'white'}}>Education</h3>
                </div>
                <TextField 
                    id="outlined-basic" 
                    label="Field of Study" 
                    variant="outlined"  
                    name="fieldOfStudy" 
                    value={fieldOfStudy}
                    onChange={handleChange('fieldOfStudy')} 
                    style={styles}
                />
                {errors.fieldOfStudy.length > 0 && <span className="error">{errors.fieldOfStudy}</span>}
                <TextField 
                    id="outlined-basic" 
                    label="School" 
                    variant="outlined"  
                    name="collegeName" 
                    value={collegeName}
                    onChange={handleChange('collegeName')} 
                    style={styles}
                />
                {errors.collegeName.length > 0 && <span className="error">{errors.collegeName}</span>}
                <TextField 
                    id="outlined-basic" 
                    label="City" 
                    variant="outlined"  
                    name="collegeLocation" 
                    value={collegeLocation}
                    onChange={handleChange('collegeLocation')} 
                    style={styles}
                />
                {errors.collegeLocation.length > 0 && <span className="error">{errors.collegeLocation}</span>}
                <label>Select your highest education</label>
                 <Select 
                    name="highestDegree"
                    options={options} 
                    onChange={handleChangeDegree}
                    // isMulti={isMulti}
                    value={highestDegree}
                    required={required}
                />
                
                <button className="Back" onClick={this.back}>Back</button>
                <button className="Next" onClick={this.continue} disabled={!isEnabled}>Next</button>
                
            </>
        )
    }
}

export default EducationDetails