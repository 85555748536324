import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Signin from '../../services/authService';
import '../../components/loginCpntCSS.css';

const APP_ID = '803819703724521';

class FacebookBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogined: false,
        };

    }

    login = (response) => {
        if (response.accessToken) {
            this.setState(state => ({
                isLogined: true,
                accessToken: response.accessToken
            }));
            const userData = {
                email: response.email,
                // accessToken: response.accessToken,
                autheticatedMedium: 'Facebook',
                // password: '',
                firstName: response.name.split(" ")[0],
                lastName: response.name.split(" ")[1]
            }

            Signin(userData).then((res) => {
                this.setState(state => ({
                    isLogined: true
                }));
                localStorage.setItem('isLogined', this.state.isLogined);
                this.props.history.push({
                    pathname: '/userprofile',
                    state: {
                        email: response.email,
                        firstName: response.name.split(" ")[0],
                        lastName: response.name.split(" ")[1]
                    }
                });
                console.log('signin success: ', res.data)
            }).catch((error) => {
                console.log('failed signin: ', error)
            });
        }
    }

    logout = (response) => {
        this.setState(state => ({
            isLogined: false,
            accessToken: ''
        }));
    }

    handleLoginFailure = (response) => {
        console.log('facebook login error: ', response)
    }

    handleLogoutFailure = (response) => {
    }

    render() {
        return (
            <div className="facebook-btn">
                <FacebookLogin buttonStyle={{ padding: "6px" }}
                    appId={APP_ID}
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={this.login}
                    onFailure={this.handleLoginFailure}
                    icon="fa-facebook"
                    render={renderProps => (
                        <button onClick={renderProps.onClick} className="facebook"><FontAwesomeIcon icon={faFacebookF} size='1x'/> Login with Facebook</button>
                    )} />
            </div>
        )
    }
}

export default withRouter(FacebookBtn)  