import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import Signin from '../../services/authService';
import '../../components/loginCpntCSS.css';
//const CLIENT_ID = '54482114691-umjeg0c51e0lbih131fpcgrf4tfgrssq.apps.googleusercontent.com';
// const CLIENT_ID = '775749613893-a869kbuaj1tbjc7scnjvdr6e3p4sddnt.apps.googleusercontent.com';
//const CLIENT_ID = '637498838357-r8sr9o20385bd2bbct5nd3qo6ngo6cbo.apps.googleusercontent.com';
//const CLIENT_ID ='58665771518-0u2ukgcpnqhvq7iahlk9a3jnhcrp6489.apps.googleusercontent.com';
const CLIENT_ID ='58665771518-qntts23gja0q9rn0vl2ic4pfma3nson1.apps.googleusercontent.com'

class GoogleBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogined: false,
            accessToken: ''
        };
    }

    login = (response) => {
        if (response.accessToken) {
            console.log('google login res: ', response)
            const userData = {
                // accessToken: response.accessToken,
                email: response.profileObj.email,
                autheticatedMedium: 'Google',
                // password: '',
                firstName: response.profileObj.givenName,
                lastName: response.profileObj.familyName,
                // image: response.profileObj.imageUrl
            };
            console.log('user data', userData)
            Signin(userData).then((res) => {
                this.setState(state => ({
                    isLogined: true
                }));
                localStorage.setItem('isLogined', this.state.isLogined);
                localStorage.setItem('email', response.profileObj.email);
                localStorage.setItem('firstName', response.profileObj.givenName);
                localStorage.setItem('lastName', response.profileObj.familyName);
                // this.props.history.push({
                //     pathname: '/userprofile',
                //     state: {
                //         email: response.profileObj.email,
                //         firstName: response.profileObj.givenName,
                //         lastName: response.profileObj.familyName,
                //     }
                // });
                localStorage.getItem('profileStatus') === 'incomplete' ? this.props.history.push("/userprofile") : this.props.history.push("/dashboard");
            }).catch((error) => {
                console.log('failed signin: ', error);
            });
        }
    }

    logout = (response) => {
        this.setState(state => ({
            isLogined: false,
            accessToken: ''
        }));
    }

    handleLoginFailure = (response) => {
        console.log('Google signin failed: ');
    }

    handleLogoutFailure = (response) => {
        alert('Failed to log out')
    }

    render() {
        return (
            <div className='google-btn'>
                { this.state.isLogined ?
                    <GoogleLogout
                        clientId={CLIENT_ID}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Log out</button>
                        )}
                        onLogoutSuccess={this.logout}
                        onFailure={this.handleLogoutFailure}
                    >
                    </GoogleLogout> : 
                    <GoogleLogin
                        clientId={CLIENT_ID}
                        render={renderProps => (
                            <button className="google" onClick={renderProps.onClick} disabled={renderProps.disabled}> <FontAwesomeIcon icon={faGoogle} size='1x'/> Login with Google</button>
                        )}
                        onSuccess={this.login}
                        onFailure={this.handleLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        responseType='code,token'
                    />
                }
                { this.state.accessToken ? <h5>Your Access Token: <br /><br /> {this.state.accessToken}</h5> : null}

            </div>
        )
    }
}

export default withRouter(GoogleBtn);