import React from "react";
import {Link} from 'react-router-dom'; 

const Footer = () => {
    return (
        <>
            <section id="footer">
        <div class="container">
            <div class="row">
                <div class="col-8 col-12-medium">

                    {/* <!-- Links --> */}
                        <section>
                            <h2>Links to Important Stuff</h2>
                            <div>
                                <div class="row">
                                    <div class="col-3 col-12-small">
                                        <ul class="link-list last-child">
                                        <li><Link  to="/contactus">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                    <div class="col-3 col-12-small">
                                        <ul class="link-list last-child">
                                        <li><a href="#">Our Technical Articles</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-3 col-12-small">
                                        <ul class="link-list last-child">
                                        <li><Link to ="/availablecourses">Technical Tranings</Link></li>
                                        </ul>
                                    </div>
                                    {/* <div class="col-3 col-12-small">
                                        <ul class="link-list last-child">
                                            <li><a href="#">Neque amet dapibus</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </section>

                </div>
                <div class="col-4 col-12-medium imp-medium">

                    {/* <!-- Blurb --> */}
                        <section>
                            <h2>About Us</h2>
                            <p>
                            We believe in innovation, but we aren’t here to reinvent the wheel. Instead, we leverage intelligent IT solutions built by industry experts, 
                            and we tailor them to your unique business needs
                            </p>
                        </section>

                </div>
            </div>
        </div>
    </section>

    {/* <!-- Copyright --> */}
    <div id="copyright">
        &copy; Escuela. All rights reserved. | Designed & Developed by Escuela Technologies
    </div>
        </>
    )
}

export default Footer;