import React from "react"
import JobDetails from "./JobDetails";
import PersonalInfo from "./PersonalInfo";
import AllInfo from "./AllInfo";
import EducationDetails from "./EducationDetails";
// import JobProfile from "./JobProfile"
import Skills from "./Skills";
import Header from "../../pages/common/header";
import Footer from "../../pages/common/footer";
import userService from "../../services/userService.jsx";
import Sidebar from "../../pages/common/sidebar";
import {withRouter} from "react-router-dom";
import CommonDashboardMarkup from "../common/CommonDashboardMarkup";

class Wizard extends React.Component{
    constructor(props){
        super(props);
        this.state = { 
            step: 1,
    
            //step1
            firstName: localStorage.getItem('firstName'),
            //When using state in history.push
            // lastName: props.location.state.lastName, 
            lastName: localStorage.getItem('lastName'),
            city: '',
            phone: '',
            email: localStorage.getItem('email'),
            profileImage: '',
    
            //step2
            jobTitle: '',
            jobCompany: '',
            jobLocation: '',
            description: '',
    
            //step3
            highestDegree: [],
            fieldOfStudy: '',
            collegeName: '',
            collegeLocation: '',
    
            //step4
            resume: null,
            // url: '',
    
            //step5
            skill: [],
            prevJobs: [],
            skills: [],
            skillOptions: [],
    
            //errors
            errors: {
                firstName: '',
                lastName: '',
                city: '',
                phone: '',
                email: '',
                jobTitle: '',
                jobCompany: '',
                jobLocation: '',
                description: '',
                highestDegree: [],
                fieldOfStudy: '',
                collegeName: '',
                collegeLocation: '',
                resume: null,
                skill: []
            }
    
        }
    }
    

    degrees = [
    { value: 'BTech', label: 'BTech' },
    { value: 'MTech', label: 'MTech' },
    { value: 'MCA', label: 'MCA' },
    { value: 'BA', label: 'BA' },
  ]

//   skills = [
//     { value: 'React', label: 'React', id: '1' },
//     { value: 'Vue', label: 'Vue', id: '2' },
//     { value: 'Angular', label: 'Angular', id: '3' },
//     { value: 'Java', label: 'Java', id: '4' },
//   ]

  componentDidMount(){
      userService.getAllSkills()
        .then(response => {
            console.log("skills from backend", response.data);
            this.setState({skills: response.data});
            console.log("skills from state",this.state.skills)
        })
  }

 

  validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  validPhoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; 
  
    nextStep = () => {
        const {step} = this.state;
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const {step} = this.state;
        this.setState({
            step: step - 1
        })
    }

    handleChange = input => e => {
        const {name, value} = e.target;
        let errors = this.state.errors;

        switch(name) {
            case 'firstName':
                errors.firstName = value == "" ? 'Please enter your First Name': '';
                break;
            case 'lastName':
                errors.lastName = value == "" ? 'Please enter your Last Name' : '';
                break;
            case 'city': 
                errors.city = value == "" ? 'Please enter your city': '';
                break;
            case 'phone': 
                errors.phone = this.validPhoneRegex.test(value) ? '' : 'Please enter a valid Phone Number';
                break;
            case 'email':
                errors.email = this.validEmailRegex.test(value) ? '' : 'Please enter a valid email';
                break;
            case 'jobTitle':
                errors.jobTitle = value == "" ? 'Please enter your Job Title' : '';
                break;
            case 'jobCompany':
                errors.jobCompany = value == "" ? 'Please enter the name of your company' : '';
                break;
            case 'jobLocation':
                errors.jobLocation = value == "" ? 'Please enter the city' : '';
                break;
            case 'description':
                errors.description = value == "" ? 'Please enter your job description' : '';
                break;
            case 'collegeName':
                errors.collegeName = value == "" ? 'Please enter name of your college/school' : '';
                break;
            case 'collegeLocation':
                errors.collegeLocation = value == "" ? 'Please enter your college/school location' : '';
                break;
            case 'fieldOfStudy':
                errors.fieldOfStudy = value == "" ? 'Please enter name of your field of study' : '';
                break;
        }

        this.setState({errors,
            [input]: e.target.value
        })
    }

    handleChangeDegree = highestDegree => {
        let errors = this.state.errors;
        if(highestDegree == ""){
            errors.highestDegree = "Please select an option";
        }
        this.setState({errors,
            highestDegree
        })
        console.log(highestDegree);
    }

    handleChangeSkills = skill => {
        this.setState({
            skill
        })
        console.log(skill);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const userProfileDetails = {
            user: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                city: this.state.city,
                phone: this.state.phone,
                email: this.state.email,
                // profileImage: this.state.profileImage,
                highestDegree: this.state.highestDegree.value,
                fieldOfStudy: this.state.fieldOfStudy,
                collegeName: this.state.collegeName,
                collegeLocation: this.state.collegeLocation
            },
            prevJobs: [{
                jobTitle: this.state.jobTitle,
                company: this.state.jobCompany,
                jobLocation: this.state.jobLocation,
                description: this.state.description
            }],
            
            skills: this.state.skill.map((item) => ({
                skillName: item.value,
                 skillId: item.id
            }))
            
            
        }
        console.log("User profile details ", userProfileDetails);
        localStorage.setItem('userdetails', JSON.stringify(userProfileDetails));
        userService.addUserProfile(userProfileDetails)
            .then(response => {
                console.log("User profile details from backend: ", response.data);
                // this.props.history.push(`/addresume/${this.state.email}`);
                this.props.history.push({
                        pathname: '/addresume',
                        state: {
                            email: localStorage.getItem('email')
                        }
                    });
            });
        
       
    }

    showStep = () => {
        const {step, errors, firstName, lastName, city, phone, email, profileImage, jobCompany, jobLocation, jobTitle, description, highestDegree, collegeLocation, collegeName, fieldOfStudy, skill, skills } = this.state;
        if(step === 1)
            return (
             <PersonalInfo 
                handleChange = {this.handleChange}
                nextStep = {this.nextStep}
                firstName = {firstName}
                lastName = {lastName}
                city = {city}
                phone = {phone}
                email = {email}
                profileImage={profileImage}
                handleChangeImage={this.handleChangeImage}
                errors={errors}
                validEmailRegex={this.validEmailRegex}
                validPhoneRegex={this.validPhoneRegex}
             />
            );
        if(step === 2)
        return (
            <EducationDetails 
                handleChange={this.handleChange}
                handleChangeDegree={this.handleChangeDegree}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                highestDegree={highestDegree}
                fieldOfStudy = {fieldOfStudy}
                collegeLocation={collegeLocation}
                collegeName={collegeName}
                // isMulti={true}
                options={this.degrees}
                errors={errors}
                required={this.required}
            />
        )
        if(step === 3)
            return (
                <JobDetails 
                    handleChange={this.handleChange}
                    nextStep = {this.nextStep}
                    prevStep = {this.prevStep}
                    jobTitle = {jobTitle}
                    jobCompany = {jobCompany}
                    jobLocation = {jobLocation}
                    description={description}
                    errors={errors}
            />
        );

        if(step === 4)
        return (
            <Skills 
                handleChangeSkills={this.handleChangeSkills}
                nextStep = {this.nextStep}
                prevStep = {this.prevStep}
                skill={skill}
                isMulti={true}
                options={skills}
                required={this.required}
            />
        )
        
        if(step === 5)
            return (
                <AllInfo 
                    firstName = {firstName}
                    lastName = {lastName}
                    city = {city}
                    phone = {phone}
                    email = {email}
                    jobTitle = {jobTitle}
                    jobCompany = {jobCompany}
                    jobLocation = {jobLocation}
                    description={description}
                    highestDegree={highestDegree}
                    collegeLocation={collegeLocation}
                    collegeName={collegeName}
                    fieldOfStudy = {fieldOfStudy}
                    skill={skill}
                    prevStep = {this.prevStep}
                    handleSubmit={this.handleSubmit}
                />
            )
    }
    

    render(){
        const {step} = this.state;
        return (
            <div>
                 <CommonDashboardMarkup>
                    <header>
                        <h2><b>Complete Your Profile</b></h2>
                    </header>
                    <header>Step {step} of 5.</header>
                    {this.showStep()}
                 </CommonDashboardMarkup>                      
            </div>
        )
    }
}

export default withRouter(Wizard);