import React from "react";
import {Link} from 'react-router-dom'; 


const Header = () => {
    return (
        <section id="header">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1><a href="index.html" id="logo">Escuela </a></h1>
                        {/* <!-- Nav --> */}
                        <nav id="nav">
                            <Link to="/landing">Home</Link>
                            <Link to="/contactus">About Us</Link>
                            <Link to="/contactus">Contact Us</Link>
                            <Link to="/availablecourses">Trainings</Link>
                            {/* <Link to="/userprofile">My Profile</Link> */}
                            {/* {localStorage.getItem('isLogined')==='true' ? <Link to="/userprofile">My Profile</Link> : <Link to="/login">Login</Link>} */}
                            {
                                localStorage.getItem('isLogined')==='true' 
                                    ? <Link to="/myprofile">My Profile</Link>
                                    : <Link to="/login">Login</Link>
                            }
                            
                        </nav>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default Header;