import React from 'react';

class DashboardContent extends React.Component {
    render(){
        return(
            <div>
        <section id="content">
           <div className ="container">
            <div className ="row aln-center">
                <div className ="col-4 col-6-medium col-12-small">
                   <h2>Escuela Technologies</h2>
                   
               </div>
               
            </div>
          </div>
        </section>
      </div>
        )
    }
}
export default DashboardContent